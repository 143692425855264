<template>

  <main-menu />
</template>

<script>
// @ is an alias to /src
import MainMenu from "@/components/MainMenu.vue";

export default {
  name: "Home",
  components: {
    MainMenu,
  },
};
</script>
