<template>
  <!-- <div class="container"> -->
    <!-- <div class="row"> -->
      <!-- <div class="col-1">
        <button
          class="btn d-flex justify-content-start align-self-start"
          type="button"
          data-bs-toggle="offcanvas"
          data-bs-target="#offcanvasLeft"
          aria-controls="offcanvasLeft"
        >
          <i class="bi bi-list"></i>
        </button>
      </div> -->

      <div class="inoagent-text">
        Настоящий материал (информация) произведен и/или распространен иностранным агентом Санкт-Петербургской общественной правозащитной организацией «Гражданский контроль» либо касается деятельности иностранного агента Санкт-Петербургской общественной правозащитной организации «Гражданский контроль»
      </div>

    <!-- </div> -->
  <!-- </div> -->
</template>
<style scoped>
.container {
  margin: 0;
  max-width: 90%;
}
.bi {
  font-size: 2em;
}
.row{
  justify-content: space-between;
}
.inoagent-text {
  margin: 1%;
  font-size: 2rem;
  text-align: start;
}
</style>